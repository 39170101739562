<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
        <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" :label="constantes.BOTAO_FILTRAR_DISPOSITIVO"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="novoDispositivo" data-cy="novo">{{constantes.BOTAO_NOVO_DISPOSITIVO}}</v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table :footer-props="footerOptions" :headers="headers" :items="dispositivos" :search="busca" :loading="carregando">
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.nome }}</td>
            <td>{{ props.item.chave }}</td>
            <td>{{ props.item.navegador }}</td>
            <td>{{ props.item.versao_navegador }}</td>
            <td class="text-center">
              <v-menu top right transition="slide-x-transition" @click.native.stop="">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item :key="props.item._id" @click="editarDispositivo(props.item)" data-cy="editar">
                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :key="props.item._id + 15" @click="remover(props.item)" data-cy="remover">
                    <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                    <v-list-item-title>Remover</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Nome', value: 'nome', sortable: false },
          { text: 'Chave', value: 'chave', sortable: false },
          { text: 'Navegador', value: 'navegador', sortable: false },
          { text: 'Versão', value: 'versao_navegador', sortable: false },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    computed: {
      ...mapState('dispositivo', {
        dispositivos: 'dispositivos',
        carregando: 'carregando'
      })
    },

    methods: {
      ...mapActions('dispositivo', {
        carregarDispositivos: 'carregar'
      }),

      novoDispositivo(){

        this.$store.commit('dispositivo/novo');
        this.$router.push({ name: 'formularioDispositivoNovo' });
      },

      editarDispositivo(dispositivo){

        this.$store.commit('dispositivo/editar', dispositivo);
        this.$router.push({ name: 'formularioDispositivoEditar' });
      },

      remover(dispositivo){

        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('dispositivo/remover', dispositivo);
          }
        });
      }
    },

    created(){
      //chamada a action através do dispatch
      this.carregarDispositivos();
    }
  }
</script>